import { createRouter, createWebHistory } from 'vue-router'
import { getCookie } from '../utils/cookie'
import Home from '../views/Home.vue'
const routes = [
    {
        path: '/',
        redirect: '/userInformation',
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/userInformation',
                name: 'userInformation',
                meta: {
                    title: '基本資料',
                },
                component: () => import('../views/Member/UserInformation/UserInformation.vue'),
            },
            {
                path: '/renew',
                name: 'renew',
                meta: {
                    title: '續費管理',
                },
                component: () => import('../views/Member/Renew/Renew.vue'),
            },
            {
                path: '/renewRecord',
                name: 'renewRecord',
                meta: {
                    title: '續費紀錄',
                },
                component: () => import('../views/Member/Renew/RenewRecord.vue'),
            },
            {
                path: '/memberEdit',
                name: 'memberEdit',
                meta: {
                    title: '修改資料',
                },
                component: () => import('../views/Member/MemberEdit.vue'),
            },
            {
                path: '/domainSourceList',
                name: 'domainSourceList',
                meta: {
                    title: '域名來源',
                },
                component: () => import('../views/Member/DomainSourceList.vue'),
            },
            {
                path: '/domainList',
                name: 'domainList',
                meta: {
                    title: '網域列表',
                },
                component: () => import('../views/Domain/DomainList.vue'),
            },
            {
                path: '/tgServices',
                name: 'tgServices',
                meta: {
                    title: '加值服務',
                },
                component: () => import('../views/Member/TgServices/TgServices.vue'),
            },
            {
                path: '/projectList',
                name: 'projectList',
                meta: {
                    title: '專案列表',
                },
                component: () => import('../views/Monitor/ProjectList/ProjectList.vue'),
            },
            {
                path: '/editProject',
                name: 'editProject',
                meta: {
                    title: '編輯專案',
                },
                component: () => import('../views/Monitor/ProjectList/EditProject.vue'),
            },
            {
                path: '/projectDetail',
                name: 'projectDetail',
                meta: {
                    title: '專案詳情',
                },
                component: () => import('../views/Monitor/ProjectList/ProjectDetail.vue'),
            },
            {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "404" */
                        '../views/404.vue'
                    ),
            },
            {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "403" */
                        '../views/403.vue'
                    ),
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录',
        },
        component: () =>
            import(
                /* webpackChunkName: "login" */
                '../views/Login.vue'
            ),
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404',
        hidden: true,
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

router.beforeEach((to, from, next) => {
    // 看cookie 裡面有沒有token, 沒有的話返回login頁面
    let token = getCookie('__tk')
    if (!token && to.path !== '/login') {
        next('/login')
    } else {
        next()
    }
})

export default router
