<template>
    <div class="about"
         :class="{ 'mobile': isMobile }">
        <v-header />
        <v-sidebar />
        <div class="content-box"
             :class="{ 'content-collapse': collapse }">
            <!-- <v-tags></v-tags> -->
            <div class="content">
                <router-view v-slot="{ Component }">
                    <transition name="move"
                                mode="out-in">
                        <keep-alive :include="tagsList">
                            <component :is="Component" />
                        </keep-alive>
                    </transition>
                </router-view>
                <!-- <el-backtop target=".content"></el-backtop> -->
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from '../components/Header'
import vSidebar from '../components/Sidebar'
// import vTags from '../components/Tags.vue'
export default {
    components: {
        vHeader,
        vSidebar,
        // vTags,
    },
    computed: {
        tagsList() {
            return this.$store.state.tagsList.map((item) => item.name)
        },
        collapse() {
            return this.$store.state.collapse
        },
        isMobile() {
            return this.$store.state.isMobile
        },
    },
    mounted() {
        let _this = this
        // 注：window.onresize只能在專案內觸發1次
        if (document.body.clientWidth < 760) {
            this.$store.commit('hadndleIsMobile', !this.isMobile)
        }
        window.onresize = function windowResize() {
            // 通過捕獲系統的onresize事件觸發我們需要執行的事件
            if (window.innerWidth < 760) {
                if (_this.isMobile == false) {
                    _this.$store.commit('hadndleIsMobile', true)
                    _this.$store.commit('hadndleCollapse', false)
                }
            } else {
                if (_this.isMobile == true) {
                    _this.$store.commit('hadndleIsMobile', false)
                }
            }
        }
    },
}
</script>
