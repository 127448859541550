import request from '../utils/request'

export const login = (query) => {
    return request({
        url: 'login/login',
        method: 'post',
        data: query,
    })
}

export const logout = () => {
    return request({
        url: 'login/logout',
        method: 'post',
    })
}

export const myInfo = () => {
    return request({
        url: 'login/me',
        method: 'get',
    })
}
//商戶-會員中心-會員資料
export const memberList = (query) => {
    return request({
        url: '/member/member/',
        method: 'get',
        params: query,
    })
}

//商戶-會員中心-會員資料修改頁面
export const memberUpdateList = (query) => {
    return request({
        url: '/member/member/update',
        method: 'get',
        params: query,
    })
}
//商戶-會員中心-會員資料修改確認
export const memberUpdate = (query) => {
    return request({
        url: '/member/member/update',
        method: 'post',
        data: query,
    })
}
//商戶-會員中心-域名來源列表
export const memberAccount = (query) => {
    return request({
        url: '/member/member/memberAccount',
        method: 'get',
        params: query,
    })
}
//取得驗證碼
export const validateCode = (query) => {
  return request({
      url: '/member/member/memberAccount/validateCode',
      method: 'get',
      params: query,
  })
}
//商戶-網域管理-網域列表
export const domainList = (query) => {
    return request({
        url: '/domain/domain',
        method: 'get',
        params: query,
    })
}
//商戶-網域管理-取得網域來源清單
export const domainSourceList = (query) => {
    return request({
        url: '/domain/domain/source',
        method: 'get',
        params: query,
    })
}
//商戶-續費管理-續費列表(顯示90天內到期域名)
export const renewList = (query) => {
    return request({
        url: '/member/renew',
        method: 'get',
        params: query,
    })
}
//商戶-續費管理-將域名加進購物車內
export const addCart = (query) => {
    return request({
        url: '/member/renew/addcart',
        method: 'post',
        data: query,
    })
}
//商戶-續費管理-單一域名變更自動續費
export const renewSetting = (query) => {
    return request({
        url: '/member/renew/setting',
        method: 'post',
        data: query,
    })
}
//商戶-續費管理-自動續費記錄列表
export const automaticRenewList = (query) => {
    return request({
        url: '/member/renew/settingHistory',
        method: 'get',
        params: query,
    })
}
//商戶-續費管理-匯出自動續約變更記錄列表至CSV
export const automaticRenewListExport = (query) => {
    return request({
        url: '/member/renew/settingHistory/export',
        method: 'post',
        responseType: 'blob',
        data: query,
    })
}

//網域列表-匯入域名備註範本下載
export const exportNoteSample = () => {
    return request({
        url: '/domain/domain/exportNoteSample',
        method: 'get',
        responseType: 'blob',
    })
}

//網域列表-匯出域名備註
export const exportNote = (query) => {
    return request({
        url: '/domain/domain/exportNote',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}

//網域列表-匯入域名備註
export const batchImportDomainNote = (query) => {
    return request({
        url: '/domain/domain/batchImportDomainNote',
        method: 'post',
        data: query,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
    })
}

//網域列表-匯出網域列表
export const exportDomainList = (query) => {
    return request({
        url: '/domain/domain/exportDomainList',
        method: 'get',
        responseType: 'blob',
        params: query,
    })
}

//網域列表-批量域名備註修改
export const updateNotes = (query) => {
    return request({
        url: '/domain/domain/updateNotes',
        method: 'post',
        data: query,
    })
}
//網域列表-送出修改名稱伺服器的請求
export const nameserverModify = (query) => {
    return request({
        url: '/domain/nameserver/modify',
        method: 'post',
        data: query,
    })
}
//網域列表-下載修改名稱次福氣的紀錄資料
export const nameserverReport = (query) => {
    return request({
        url: '/domain/nameserver/report',
        method: 'get',
        params: query,
        responseType: 'blob',
    })
}
//網域列表-確認中斷碼是否存在Cache表中
export const checkInterruptCode = (query) => {
    return request({
        url: '/global/checkInterruptCode',
        method: 'get',
        params: query,
    })
}

//後台-商戶管理-取得加值服務總攬
export const valueAddedSummary = (query) => {
    return request({
        url: '/member/valueAdded/summary',
        method: 'get',
        params: query,
    })
}
//後台-商戶管理-取得加值服務設定
export const valueAdded = (query) => {
    return request({
        url: '/member/valueAdded/update',
        method: 'get',
        params: query,
    })
}
//後台-商戶管理-取得加值服務使用紀錄
export const valueAddedHistory = (query) => {
    return request({
        url: '/member/valueAdded/history',
        method: 'get',
        params: query,
    })
}

//手動同步DNS Nameserver
export const syncDNSNameserver = (query) => {
    return request({
        url: '/domain/domain/syncDNSNameserver',
        method: 'post',
        data: query,
    })
}

//確認同步DNS Nameserver是否仍在執行中
export const isDNSNameserverSyncing = (query) => {
    return request({
        url: '/domain/domain/isDNSNameserverSyncing',
        method: 'get',
        params: query,
    })
}

//取消同步DNS Nameserver
export const cancelDNSNameserver = (query) => {
    return request({
        url: '/domain/domain/cancelDNSNameserverSyncing',
        method: 'post',
        data: query,
    })
}
//專案管理-專案列表
export const projectList = (query) => {
    return request({
        url: '/monitor/project',
        method: 'get',
        params: query,
    })
}

//專案管理-專案列表-修改頁面
export const updateProjectList = (query) => {
    return request({
        url: '/monitor/project/update',
        method: 'get',
        params: query,
    })
}
//專案管理-專案列表-修改確認
export const updateProject = (query) => {
    return request({
        url: '/monitor/project/update',
        method: 'post',
        data: query,
    })
}

//專案管理-專案列表-瀏覽專案
export const showProjectList = (query) => {
    return request({
        url: '/monitor/project/show',
        method: 'get',
        params: query,
    })
}
//專案網域管理-專案網域列表
export const projectDomainList = (query) => {
    return request({
        url: '/monitor/projectDomain',
        method: 'get',
        params: query,
    })
}
