import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'
import JsonViewer from 'vue3-json-viewer'
import VueClipboard from 'vue3-clipboard'

const app = createApp(App)
app.config.devtools = true
installElementPlus(app)

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

app.use(store)
    .use(router)
    .use(JsonViewer)
    .mount('#app')
