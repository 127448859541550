export const setCookie = (name, value = '', time, type = 'days') => {
    let expires = new Date()

    switch (type) {
        case 'days':
        default:
            expires.setTime(expires.getTime() + time * 24 * 60 * 60 * 1000)
            break
        case 'hour':
            expires.setTime(expires.getTime() + time * 60 * 60 * 1000)
            break
    }

    document.cookie = `${name}=${value};expires=${expires.toGMTString()}`
    return
}

export const getCookie = (name) => {
    let cookies = document.cookie
    let cookieValue = cookies.split(';').find((row) => {
        return row.startsWith(name)
    })

    return cookieValue ? cookieValue.split('=')[1] : null
}

export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    return
}
